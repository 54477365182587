@font-face {
    font-family:'Circular Black';
    src: url('./webfonts/lineto-circular-black.eot');
    src:    url('./webfonts/lineto-circular-black.eot?#iefix') format('embedded-opentype'),
    url('./webfonts/lineto-circular-black.woff') format('woff'),
    url('./webfonts/lineto-circular-black.woff2') format('truetype');
}

@font-face {
    font-family:'Circular Bold';
    src: url('./webfonts/lineto-circular-bold.eot');
    src:    url('./webfonts/lineto-circular-bold.eot?#iefix') format('embedded-opentype'),
    url('./webfonts/lineto-circular-bold.woff') format('woff'),
    url('./webfonts/lineto-circular-bold.woff2') format('truetype');
}

@font-face {
    font-family:'Circular Book';
    src: url('./webfonts/lineto-circular-book.eot');
    src:    url('./webfonts/lineto-circular-book.eot?#iefix') format('embedded-opentype'),
    url('./webfonts/lineto-circular-book.woff') format('woff'),
    url('./webfonts/lineto-circular-book.woff2') format('truetype');
}