/* VARS - WEBSITE =====================================
======================================================= */
$white: #FFFFFF;
$black: #000000;
$rethink-blue: #03beff;
$rethink-grey: #393939;

// General

// FONTS ============================================== */
$baseFont: arial, sans-serif;
$circularBlack: 'Circular Black', arial, sans-serif;
$circularBook: 'Circular Book', arial, sans-serif;
$circularBold: 'Circular Bold', arial, sans-serif;
/* BREAKPOINTS ======================================== */

// smartphones (portrait view)
$screen-xs: 374px;

// Small tablets and large smartphones (landscape view)
$screen-sm: 576px;

// Small tablets (portrait view)
$screen-md: 768px;

// Tablets and small desktops
$screen-lg: 1024px;

// Large tablets and desktops
$screen-xl: 1200px;

// Super large
$screen-max: 1600px;
