@import '../../assets/scss/partials/vars';
@import '../../assets/scss/partials/fonts';

.home {

    .hero {
        position:relative;
        background-color:$rethink-blue;
        min-height: 600px;

        .logo {
            width:65px;
            margin-top:40px;
        }

        h1 {
            margin-top:150px;
            color: #fff;
            font-family: $circularBlack;
           
            @media(min-width:850px) {
                font-size: 5vw;
            }
            @media(min-width:1200px) {
                font-size: 4rem;
            }
            span {
                display:block;
                color:$rethink-grey;
            }
        }

        .dogs {
         position:relative;
          //  justify-content: space-between;
         //  width:100%;
            div {
              
           //     width: 100%;
               // padding-top:15px;

                @media(min-width:850px) {
           //         width: 700px;
                  
                }
                @media(min-width:1000px) {
           //         width: 800px;
                }
                @media(min-width:1200px) {
             //       width: 800px;
                }
                @media(min-width:1600px) {
            //        width: 1000px;
                 
                }
            }
            img {
                width:200px;
                //max-width:50%;
                margin-top:70px;
              @media(min-width:768px) {
                width: 300px;
                margin-top:-10px;
                }
                @media(min-width:850px) {
                    width: 300px;
                    margin-top:-60px;
                }
                @media(min-width:1000px) {
                    width: 350px;
                    margin-top:-110px;
                }
                @media(min-width:1200px) {
                    width: 420px;
                    margin-top:-200px;
                }
                @media(min-width:1400px) {
                    width: 450px;
                    margin-top:-220px;
                }
                @media(min-width:1600px) {
                    width: 500px;
                    margin-top:-250px;
                }
                @media(min-width:1900px) {
                    width: 580px;
                    margin-top:-320px;
                }
            
            }
            .dogs-left {
                position:absolute;
                left:0;
                
            }
           .dogs-right {
               // position:absolute;
               // right: 0;
                margin-right:-65%;
                @media(min-width:$screen-sm) {
                    margin-right:-75%;
                }
            }
        }
    }

    .container {
        .main-text {
            max-width:600px;
            margin: 0 auto;
            line-height:1.40;
            padding: 0 20px;
            margin-top: 70px;
            font-family: $circularBook;
            font-size: 1.5rem;
            text-align: center;
            margin-bottom: 70px;
        }
    }

    .video-wrapper {
        display:block;
        padding: 30px;
        border-radius:20px;
        margin-bottom:80px;
        flex-direction: row;
        align-items: center;
        text-align: center;
        background-color: #f1f1f1;
        @media(min-width:$screen-md) {
            padding: 30px;
            display:flex;
            text-align:left;
        }
        @media(min-width:$screen-lg) {
            padding: 40px;
            display:flex;
            text-align:left;
        }
        .video-copy-share {
            display: block;
            flex: 1.2 1 0;
           

            @media(min-width:$screen-md) {
                padding-right:50px;
            }
            h2 {
                color: $rethink-blue;
                font-family: $circularBold;
                font-size: 1.5rem;
                text-align:center;
              
                @media(min-width:$screen-md) {
                    font-size: 1.4rem;
                    text-align: left;
                }
                @media(min-width:$screen-lg) {
                    font-size: 1.9rem;
                }
            }

            .share-wrapper {
                margin-bottom:20px;
                span {
                    margin-top:5px;
                    display:block;
                }
                @media(min-width:$screen-md) {
                    margin-bottom: 0;
                }
                .share-title {
                    display:inline-block;
                    @media(min-width:$screen-md) {
                       display:none;
                    }
                    @media(min-width:850px) {
                        display:inline-block;
                    }
                }

                .social-icon {
                    margin: 0 10px;
                }

                .social-icon:first-child {
                    margin-left: 0;
                }
            }
          
            
        }
        .video-container {
          
            display:block;
            flex: 2 1 0;
           
            .youtube-container {
             //   max-width:640px;
                margin: 0 auto;
                .youtube {
                    overflow: hidden;
                    position: relative;
                    height: 0;
                    width: 100%;
                    padding-bottom: 56.25%;
                }
                  
                .youtube iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }
            }  
        }
       
    }

    .pet-insurance {
        h2 {
            color: $rethink-blue;
                font-family: $circularBold;
                font-size: 1.5rem;
                text-align:center;
              
                @media(min-width:$screen-md) {
                    font-size: 1.4rem;
                   
                }
                @media(min-width:$screen-lg) {
                    font-size: 1.9rem;
                }
        }

        ul {
            list-style-type: none;
            margin: 30px 0 80px 0;
            li {
               display:block;
              
                @media(min-width:$screen-md) {
                    display: inline-block;
                    border-right:1px solid #999;
                }
            }
            li:last-child {
                border-right:0;
            }
        }

        img {
            max-height:50px;
            margin: 0 0 20px 0;
            opacity: 0.8;
            transition: 0.3s;
            @media(min-width:$screen-md) {
                max-height:40px;
                margin: 0 20px;
            }
        }

        img:hover {
            opacity:.5;
        }
    }

    footer {
        background-color: #707070;
        .social-icon {
            margin: 80px 5px 20px 5px;
        }
        span {
            font-size: .6rem;
            display: block;
            color: #b0b0b0;
            padding-bottom:80px;
        }
    }

    .social-wrapper .social-icon {
        margin: 5px;
    }

    .social-wrapper .social-icon:first-child {
        margin-left:0;
    }


    .social-icon-class .social-svg-icon {
        fill: #7d7d7d;
    }
    .social-icon-class:hover .social-svg-mask {
        fill:#6bbffd !important;
    }  
}

